import InWorkTable from "./OrdersTable"
import ReactPaginate from "react-paginate";
import AuthContext from "../AuthContext";
import {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {setData} from "../../Reducers/Orders"
import ApiAdapter from "../../Adapters/ApiAdapter";
import DataRepository from "../../Repositories/DataRepository";

const InWorkOrders = () => {

    const orData = useSelector(state => state.Orders.data)

    const itemsPerPage = 10;

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;

        if (orData) {
            setCurrentItems(orData.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(orData.length / itemsPerPage));
        }
    }, [itemOffset, itemsPerPage, orData]);

    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % orData.length;
        setItemOffset(newOffset);
    };

    return (
        <div className="card card-border mb-0 h-100">
            <div className="card-header card-header-action">
                <h6>Замовлення
                    <span className="badge badge-sm badge-light ms-1">{orData.length}</span>
                </h6>
                <div className="card-action-wrap">
                    {/*  Free  */}
                </div>
            </div>
            <div className="card-body">
                <div className="contact-list-view">

                    <div id="datable_1_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                        {/*Head*/}
                        <div className="row">
                            <div className="col-10 mb-3">
                                <div className="contact-toolbar-left">

                                    {/*<div className="input-group w-230p mx-3">*/}
                                    {/*    <span className="input-affix-wrapper">*/}
                                    {/*        <span className="input-prefix">*/}
                                    {/*            <span className="feather-icon">*/}
                                    {/*                <FontAwesomeIcon icon={faCalendar} className="feather me-2"/>*/}
                                    {/*            </span>*/}
                                    {/*        </span>*/}
                                    {/*    </span>*/}
                                    {/*</div>*/}

                                </div>
                            </div>
                            <div className="col-2 mb-3">
                                <div className="contact-toolbar-right">
                                    <div id="datable_1_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="form-control form-control-sm"
                                                   placeholder="Пошук" aria-controls="datable_1"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Head*/}
                        {/*Table*/}
                        <div className="row">
                            <div className="col-sm-12 table-responsive">
                                <InWorkTable data={currentItems}/>
                            </div>
                        </div>
                        {/*Table*/}
                        {/*Pagination*/}
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-end">
                                    <ReactPaginate
                                        className={"pagination custom-pagination pagination-simple pagination-sm"}
                                        nextLabel=" >>"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={pageCount}
                                        previousLabel="<< "
                                        pageClassName="me-1 ms-1 paginate_button page-item"
                                        pageLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                        previousClassName="page-item me-1"
                                        previousLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                        nextClassName="page-item ms-1"
                                        nextLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link fs--1 shadow pe-3 ps-3 rounded"
                                        containerClassName="pagination mb-0"
                                        activeClassName="active "
                                        renderOnZeroPageCount={null}
                                    />

                                </div>
                            </div>
                        </div>
                        {/*Pagination*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

const getData = async (dispatch) => {

    const apiAdapter = ApiAdapter();
    const {getOrdersInWork} = DataRepository(apiAdapter);
    await getOrdersInWork(dispatch, setData)
}

const InWork = () => {

    const dispatch = useDispatch()
    // const navigate = useNavigate()

    // let {isAuthenticated} = useContext(AuthContext)

    useEffect(() => {
        // if (isAuthenticated === true) {
        getData(dispatch).then();
        // }
        // else
        //     navigate("/")

    }, []);

    return (
        <div className="container-xxl">
            <div className="hk-pg-header pt-7 pb-4">
                <h1 className="pg-title">За статусом</h1>
            </div>
            <div className="hk-pg-body">
                <div className="row">
                    <div className="col-md-12 mb-md-4 mb-3">
                        <InWorkOrders/>
                        {/*<ErrorNotification position={'bottom-left'}/>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InWork