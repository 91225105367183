// import './css/style.css'
import './scss/style.scss'

import {Routes, Route, Navigate} from "react-router-dom";
import {AuthProvider} from "./Components/AuthContext";

import {PrivateRoute, AdminRoute} from "./Components/PrivateRoute";

import Login from "./Components/Authentication/Login/Login";
import Signup from "./Components/Authentication/Signup/Signup";
import Main from "./Components/Main/Main";
import Profile from "./Components/Users/Profile";
import Shipments from "./Components/Orders/Shipments";
import PageContext from "./Components/PageContext";
import InWork from "./Components/Orders/InWork";
import OrderPreview from "./Components/Orders/OrderPage/OrderPreview";
import Archive from "./Components/Orders/Archive";
import DebtAnalysis from "./Components/Reports/DebtAnalysis/DebtAnalysis";

import Notification from "./Components/Notifications/Notification";
import ErrorNotification from "./Components/Notifications/ErrorNotification";
import News from "./Components/Pages/Blog/News";

import AdminNews from "./Components/Admin/News/News";
import AddPost from "./Components/Admin/News/AddPost"

import AdminUsers from "./Components/Admin/AdminUsers";
import Article from "./Components/Pages/Blog/Article";
import Support from "./Components/Support/Support";
import Page404 from "./Components/Pages/404";

function App() {
    return (
        <div>
            <ErrorNotification/>
            <Notification/>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<PrivateRoute>
                        <PageContext> <Main/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/orders/in-work" element={<PrivateRoute>
                        <PageContext> <InWork/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/orders/shipments" element={<PrivateRoute>
                        <PageContext> <Shipments/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/orders/archive" element={<PrivateRoute>
                        <PageContext> <Archive/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/orders/order-details/:uid"
                           element={<PrivateRoute> <PageContext> <OrderPreview/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/reports/debt-analysis" element={<PrivateRoute>
                        <PageContext> <DebtAnalysis/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/news" element={<PrivateRoute>
                        <PageContext> <News/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/support" element={<PrivateRoute>
                        <PageContext> <Support/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/news/article/:slug" element={<PrivateRoute>
                        <PageContext> <Article/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/users/profile" element={<PrivateRoute>
                        <PageContext> <Profile/> </PageContext>
                    </PrivateRoute>}/>

                    <Route path="/users/profile" element={<PrivateRoute>
                        <PageContext> <Profile/> </PageContext>
                    </PrivateRoute>}/>

                    {/*Error elements*/}
                    <Route path="/404" element={<PrivateRoute>
                        <PageContext> <Page404/> </PageContext>
                    </PrivateRoute>}/>
                    {/*...Error elements*/}

                    {/*Admin elements*/}
                    <Route path="/admin-news" element={<AdminRoute>
                        <PageContext> <AdminNews/> </PageContext>
                    </AdminRoute>}/>
                    <Route path="/admin-news/add" element={<AdminRoute>
                        <PageContext> <AddPost/> </PageContext>
                    </AdminRoute>}/>
                    <Route path="/admin-news/:slug" element={<AdminRoute>
                        <PageContext> <AddPost/> </PageContext>
                    </AdminRoute>}/>

                    <Route path="/admin-users" element={<AdminRoute>
                        <PageContext> <AdminUsers/> </PageContext>
                    </AdminRoute>}/>
                    {/*...Admin elements*/}

                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="*" element={<Navigate to="/404"/>}/>

                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
