import {handleError} from "../Utils/Errors";
import {repositoryResponse, errorResponse} from "./Response";

const DataRepository = (apiAdapter) => ({

    getOrder: async (uid, dispatch, setData) => {
        await apiAdapter.get(`/orders/order-details/${uid}/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, setData);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },

    getOrdersInWork: async (dispatch, setData) => {
        await apiAdapter.get(`/orders/orders-list/`)
            .then(function (response) {
                repositoryResponse(response.status, response.data, dispatch, setData);
            })
            .catch(function (error) {
                errorResponse(error.response.status, dispatch, error)
            });
    },

    getOrderPrintDoc: async (uid) => {

        try {
            const response = await apiAdapter.get(`/orders/order-print-request/${uid}/`)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {errors: handleError(error)}
        }
    },

    updateDeliveryDate: async (uid, delivery_date) => {

        const data = {delivery_date: delivery_date}

        try {
            const response = await apiAdapter.post(`/orders/delivery-date-update-request/${uid}/`, data)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {errors: handleError(error)}
        }
    },

    updateDeliveryAddress: async (uid, address) => {

        const data = {address: address}

        try {
            const response = await apiAdapter.post(`/orders/address-update-request/${uid}/`, data)
            return {data: response.data, status: response.status};
        } catch (error) {
            return {errors: handleError(error)}
        }

    }

});

export default DataRepository;