import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';
import locale_ukUA from "../../Utils/drpl_uk-UA";
import {Form} from "react-bootstrap";
import {setPeriod, setDate} from "../../Reducers/DateRangePicker";
import {useDispatch} from "react-redux";
import moment from "moment/moment";

export function Range({useMinDate=true}){

    const dispatch = useDispatch();

    const storageStartDate = localStorage.getItem('DRPStartDate')
    const storageEndDate = localStorage.getItem('DRPEndDate')

    localStorage.removeItem('DRPStartDate')
    localStorage.removeItem('DRPEndDate')

    let initialState = {
                startDate: storageStartDate ? moment(storageStartDate) : moment(),
                endDate: storageEndDate ? moment(storageEndDate) : moment(),
                // minDate: moment(),
                // startDate: initialStartDate,
                // endDate: initialEndDate,
                autoApply: true,
                showDropdowns: true,
                locale: locale_ukUA,
            }

    if (useMinDate){
        initialState.minDate = moment()
    }

    const handleDateRangeCallback = (start, end) => {
        dispatch(setPeriod({startDate: start.format("YYYYMMDD"), endDate: end.format("YYYYMMDD")}));
    };

    return (
        <DateRangePicker
            onCallback={handleDateRangeCallback}
            initialSettings={initialState}>
            <Form.Control type="text" name="datetimes" readOnly={true}/>
        </DateRangePicker>
    )
}

export function SelectDate(){

    const dispatch = useDispatch();

    const handleDateRangeCallback = (start, end) => {
        dispatch(setDate(start.format("YYYYMMDD")));
    };

    return (
        <DateRangePicker
            onCallback={handleDateRangeCallback}
            initialSettings={{
                singleDatePicker: true,
                startDate: moment(),
                autoApply: true,
                showDropdowns: true,
                locale: locale_ukUA,
            }}>
            <Form.Control type="text" name="datetimes" readOnly={true}/>
        </DateRangePicker>
    )
}

export function RangeForm({id, onCallback, useMinDate=true}){

    const dispatch = useDispatch();

    let initialState = {
                startDate: moment(),
                endDate: moment(),
                // minDate: moment(),
                // startDate: initialStartDate,
                // endDate: initialEndDate,
                autoApply: true,
                showDropdowns: true,
                locale: locale_ukUA,
            }

    if (useMinDate){
        initialState.minDate = moment()
    }

    // const handleDateRangeCallback = (start, end) => {
    //     dispatch(setPeriod({startDate: start.format("YYYYMMDD"), endDate: end.format("YYYYMMDD")}));
    // };

    return (
        <DateRangePicker
            onCallback={onCallback}
            initialSettings={initialState}>
            <Form.Control type="text" name="datetimes" id={id} readOnly={true} />
        </DateRangePicker>
    )
}

export function SelectDateForm({id, name, onCallback, readOnly=true, startDate=moment()}){

    return (
        <DateRangePicker
            onCallback={onCallback}
            initialSettings={{
                singleDatePicker: true,
                startDate: startDate,
                autoApply: true,
                showDropdowns: true,
                locale: locale_ukUA,
            }}>
            <Form.Control type="text" id={id} name={name} readOnly={readOnly}/>
        </DateRangePicker>
    )
}

